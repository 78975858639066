import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';
import { FormProvider, RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { Iconify } from 'src/components/Iconify';
import { Link } from 'src/components/Link';
import { useAuth } from 'src/contexts/AuthProvider';
import { useIsMountedRef } from 'src/hooks/useIsMountedRef';
import { PATH_AUTH } from 'src/paths';

interface FormValuesProps {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
}

const defaultValues = {
  email: '',
  password: '',
  remember: false,
};

export function LoginForm() {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Bitte eine gültige E-Mail-Adresse angeben')
      .required('Bitte eine E-Mail-Adresse angeben'),
    password: Yup.string().required('Bitte ein Passwort angeben'),
    remember: Yup.bool().required(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  async function onSubmit(data: FormValuesProps) {
    try {
      await login(data.email, data.password);
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="E-Mail-Adresse" />

        <RHFTextField
          name="password"
          label="Passwort"
          type={showPassword ? 'text' : 'password'}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Dauerhaft eingeloggt bleiben" />
        <Link href={PATH_AUTH.resetPassword} variant="subtitle2">
          Passwort vergessen?
        </Link>
      </Stack>

      <LoadingButton loading={isSubmitting} fullWidth size="large" type="submit" variant="contained">
        Einloggen
      </LoadingButton>
    </FormProvider>
  );
}
