import { ReactNode, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Account, useMyAccountQuery } from 'src/api';
import { ErrorFallback } from 'src/components/ErrorFallback';
import { NavSectionHorizontal } from 'src/components/nav-section/horizontal/NavSectionHorizontal';
import { HEADER, NAVBAR } from 'src/config';
import { useSettings } from 'src/contexts/SettingsContext';
import { useCollapseDrawer } from 'src/hooks/useCollapseDrawer';
import { useResponsive } from 'src/hooks/useResponsive';
import { AppHeader } from './header/AppHeader';
import { NavbarVertical } from './navbar/NavbarVertical';
import { navConfig } from './navbar/NavConfig';

interface MainStyleProps {
  collapseClick: boolean;
}

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: 16,
  paddingBottom: 16,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

export function AppLayout({ children }: { children: ReactNode }) {
  const myAccount = useMyAccountQuery().data?.myAccount;
  const { collapseClick } = useCollapseDrawer();
  const { themeLayout } = useSettings();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);

  const navItems = navConfig.filter((navItem) =>
    navItem.isEnabled ? navItem.isEnabled(myAccount as Account) : true,
  );

  if (themeLayout === 'vertical') {
    return (
      <>
        <AppHeader onOpenSidebar={() => setOpen(true)} verticalLayout>
          {isDesktop && <NavSectionHorizontal data={navItems} />}
        </AppHeader>

        {isDesktop || (
          <NavbarVertical data={navItems} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT}px`,
            },
          }}
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
        </Box>
      </>
    );
  }

  return (
    <Box display={{ lg: 'flex' }} minHeight={{ lg: 1 }}>
      <NavbarVertical data={navItems} isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle collapseClick={collapseClick}>{children}</MainStyle>
    </Box>
  );
}
