import { m } from 'framer-motion';
import { Container, Typography } from '@mui/material';
import { MotionContainer } from 'src/components/animate/MotionContainer';
import { varBounce } from 'src/components/animate/variants';
import { useAuth } from 'src/contexts/AuthProvider';

interface RoleBasedGuardProp {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
}

export function RoleBasedGuard({ hasContent, roles, children }: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user } = useAuth();

  // const currentRole = 'user';
  const currentRole = user?.role; // admin;

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3">Zugang verweigert</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Sie haben nicht die nötigen Rechte für diese Seite
          </Typography>
        </m.div>
      </Container>
    ) : null;
  }

  return children;
}
