import { FallbackProps } from 'react-error-boundary';
import { Alert, AlertTitle, Button, Stack, Typography } from '@mui/material';
import { LinkButton } from 'src/components/LinkButton';

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    <Stack alignItems="center" gap={3} maxWidth={480} mx="auto" pt={12}>
      <Alert severity="error" sx={{ width: '100%' }}>
        <AlertTitle>Es ist ein Fehler aufgetreten</AlertTitle>
        <Typography fontFamily="monospace">{error.message}</Typography>
      </Alert>

      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <LinkButton href="/" size="large" variant="outlined">
          Zurück zur Startseite
        </LinkButton>
        oder
        <Button size="large" variant="outlined" onClick={resetErrorBoundary}>
          Noch mal versuchen
        </Button>
      </Stack>
    </Stack>
  );
}
