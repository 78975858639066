import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { MenuItem, Select, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useMyAccountQuery } from 'src/api';
import { IconButtonAnimate } from 'src/components/animate/IconButtonAnimate';
import { MenuPopover } from 'src/components/MenuPopover';
import { MyAvatar } from 'src/components/MyAvatar';
import { useAuth } from 'src/contexts/AuthProvider';
import { AccountMenu } from './AccountMenu';

export function AccountPopover() {
  const { accountId, switchAccount, user } = useAuth();
  const myAccount = useMyAccountQuery({ skip: !accountId }).data?.myAccount;
  const currentAccount = myAccount?.userAccounts?.find((account) => account.id === accountId);

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(null);

  useEffect(() => {
    if (user && currentAccount?.name) {
      Sentry.setUser({
        id: user.uid,
        username: user.displayName,
        email: user.email,
        segment: currentAccount?.name,
      });

      Sentry.setContext('Club', {
        id: currentAccount.id,
        name: currentAccount.name,
        userRole: currentAccount.role,
      });
    }
  }, [user?.uid, currentAccount?.name]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {accountId && (myAccount?.userAccounts?.length ?? 0) > 1 ? (
        <Select
          value={accountId}
          onChange={(event) => switchAccount(event.target.value)}
          size="small"
          fullWidth
        >
          {myAccount?.userAccounts?.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} noWrap>
          {myAccount?.name}
        </Typography>
      )}
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <AccountMenu handleClose={handleClose} />
      </MenuPopover>
    </>
  );
}
