import { useAuth } from 'src/contexts/AuthProvider';
import { createAvatar } from 'src/utils/createAvatar';
import { Avatar, AvatarProps } from './Avatar';

export function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useAuth();

  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
