import { forwardRef } from 'react';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { Button, ButtonProps } from '@mui/material';

interface LinkButtonProps extends Omit<ButtonProps, 'href'> {
  href: LinkProps['href'];
}

export const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(
  ({ href, children, ...buttonProps }, ref) => {
    const router = useRouter();
    return (
      <Button ref={ref} onClick={() => router.push(href)} {...buttonProps}>
        {children}
      </Button>
    );
  },
);

LinkButton.displayName = 'LinkButton';
